@supports ((backdrop-filter: blur(22px)) or (-webkit-backdrop-filter: blur(22px))) {
  .blurred1 {
    -webkit-backdrop-filter: blur(22px);
    backdrop-filter: blur(22px);
  }
    .blurred2 {
    -webkit-backdrop-filter: blur(22px);
    backdrop-filter: blur(22px);
  }
  .blurred {
    -webkit-backdrop-filter: blur(22px);
    backdrop-filter: blur(22px);
  }
  .copydialog {
    -webkit-backdrop-filter: blur(22px);
    backdrop-filter: blur(22px);
  }
  .hidedialog {
    -webkit-backdrop-filter: blur(22px);
    backdrop-filter: blur(22px);
  }
  .deletedialog {
    -webkit-backdrop-filter: blur(22px);
    backdrop-filter: blur(22px);
  }
   .itemoptions {
    -webkit-backdrop-filter: blur(22px);
    backdrop-filter: blur(22px);
  }
}