.guestGraph.graphstoday, .div-block-924{
    background: unset;
}
.guestGraph.graphstoday canvas{
    max-height: 100%;
    width: 97%;
}
.div-block-877{
    /* overflow: auto; */
}
#nowInHouse{
    background: #17a6b7;
    width: 0px;
    margin-left: 0px;
    border-radius: 0px 16px 16px 0px;
}
.div-block-926{
    /* margin-top: 15px; */
}