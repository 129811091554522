.topSellerCard{
    position: relative;
    padding: 66px 11px 11px;
    border-radius: 33px;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}
.topSellContainer{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.gridSellerContainer{
    display: -ms-grid;
    display: grid;
    margin: 11px;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
}
.divhome{
    margin: auto;
}