.body-bg {
    display: flex;
    height: 100vh;
}

#root {
    width: -webkit-fill-available;
}

.navbar {
    margin-left: 5px;
    margin-right: 5px;
    width: -webkit-fill-available;
}

/* grid layout with 3 coloms */

.col-3 {
    height: -webkit-fill-available;
    padding: 0 5px 5px 5px;
    width: 100%;
    overflow: auto;
}

.col-3 .box-1, .col-3 .box-2, .col-3 .box-3 {
    background-color: #001925;
    border-radius: 28px;
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
}

.box-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 30px;
    flex-wrap: wrap-reverse;
}

.col-3 .box-wrapper {
    background-color: #002d40;
    border-radius: 28px;
    height: -webkit-fill-available;
    box-shadow: 0px 10px 12px 0px #071014, inset 0px -15px 25px 0px #003751;
    border-bottom: 2px solid #014561;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 5px;
    min-height: 400px;
}

.col-3 .box-wrapper .vertical-center {
    align-items: center;
}

.col-3 .footer-title {
    font-family: Montserrat, sans-serif;
    color: #003f5a;
    font-size: 33px;
    line-height: 33px;
    font-weight: 700;
    letter-spacing: -2px;
    margin: 15px 0 15px 0px;
}

.col-3 .footer-button {
    padding: 0 7px;
}

.col-3 .footer-buttons-wrapper {
    display: flex;
    margin: 0 0 auto 0;
    font-family: Montserrat, sans-serif;
    color: #17a6b7;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    cursor: pointer;
    background-color: #002d40;
    border-bottom: 1px solid #014561;
    border-radius: 0 0 15px 15px;
    padding: 10px;
    align-items: center;
}

.col-3 .footer-buttons-wrapper .footer-button:hover {
    color: #ff414d;
}

.col-3 .footer-buttons-wrapper .footer-button-icon{
    padding-left: 7px;
    font-size: 19px;
    font-weight: 700;
}