.body-bg {
    height: auto!important;
}

.navbarHeader {
    background-color:#c3dde5d1;
    backdrop-filter: blur(6px);
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;    
    width: -webkit-fill-available;
    margin: 22px;
    border-radius: 30px;
    padding: 10px;
    position: sticky;
    top: 5px;
    z-index: 3;
}

@-moz-document url-prefix() {
    .navbarHeader {
        width: -moz-available;
        background-color:#c3dde5FF;
    }
    }

.navBarLogo {
    height: 44px;
}

.dropDownLogin {
    margin-top: 42px;
    position: absolute;
    width: min-content;
    background-color: #c8dfe8;
    border-radius: 20px;
    border: #98b5c1 solid 1px;
    flex-direction: column;
    display: none;
    overflow: hidden;
}

#loginWrapper {
    display: flex;
    justify-content: flex-end;
}

#langSelectorWrapper {
    display: flex;
    justify-content: flex-end;
}

#langSelectorDropdown {
    margin-top: 28px;
    position: absolute;
    width: min-content;
    background-color: #c8dfe8;
    border-radius: 20px;
    border: #98b5c1 solid 1px;
    flex-direction: column;
    padding: 5px;
    display: none;
}