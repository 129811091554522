/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

*/
.body-bg {
  background-color: #d9ecf2;
}

/* * {
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 0px;
}


::-webkit-scrollbar-thumb {
  background: #002d40;
  border-radius: 0px;
}


::-webkit-scrollbar-thumb:hover {
  background: #17a6b7;
}
 */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}




/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 */
/* .bodyadmin{
  min-height: 100vh;
} */
input[type="submit" i]:focus{
  outline: unset;
}
p.validationsError{
  margin: 0px;
    font-size: 12px;
    color: #b90707;
}


.table-count:hover .table-edit{ display: block;}

#root{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.app-backdrop{
  opacity: 1;
  background-color: transparent;
  -webkit-backdrop-filter: blur(22px);
  backdrop-filter: blur(22px);
}




/*
* Date Picker CSS changes starts
*/

.appDatePicker.react-datepicker{
  background-color: transparent;
  border: 0;
}

.appDatePicker .react-datepicker__header  {
  background-color: transparent;
    padding: 0;
    border: 0;
    padding: 10px 10px 0px;
}


.appDatePicker .react-datepicker__current-month{
  background-color: #003f5a;
    font-family: Montserrat, sans-serif;
    color: #17a6b7;
    font-size: 14px;
    /* line-height: 14px; */
    font-weight: 700;
    padding: 8px 0;
    border-radius: 22px;

}
.appDatePicker .react-datepicker__day-names{
  /* display: none; */
  margin-top: 5px;

}

.appDatePicker  .react-datepicker__day-name{
  border-radius: 22px;
  background-color: #17a6b7;
  color: #002034;
  font-size: 11px;
  line-height: 11px;
  font-weight: 700;
  padding: 5px;
  width: 35px;
}

.appDatePicker .react-datepicker__navigation {
  background-color: #17a6b7;
  border-radius: 22px;
  width: 50px;
  padding: 11px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-color: transparent;
  background-size: 20px;
  border:0;
  height: 36px;
}

.appDatePicker .react-datepicker__day{
  background-color: #003f5a;
  font-family: Montserrat, sans-serif;
  color: #17a6b7;
  font-size: 14px;
  line-height: 35px;
  font-weight: 700;
  border-radius: 22px;
  /* padding: 5px 10px; */
  width: 35px;
}

.appDatePicker .react-datepicker__day:hover{
  border-radius: 22px;
  background-color: #f0f0f0;
}

.appDatePicker .react-datepicker__month-container {
  /* background: transparent; */
  border-radius: 33px;
  background-color: #002d40;
  padding-bottom: 15px;
}
.appDatePicker {
  background: transparent;
  border: 0;
}

.appDatePicker .react-datepicker__triangle{
  background: transparent !important;
  border-color: transparent !important;
}
.appDatePicker .react-datepicker__triangle::before{
  background: transparent !important;
  border-color: transparent !important;
}
.appDatePicker .react-datepicker__triangle::before{
  background: transparent !important;
  border-color: transparent !important;
}

.appDatePicker .react-datepicker__navigation--next{
  background-image: url("./images/right.svg");
  /* border-left-color: transparent; */

}

.appDatePicker .react-datepicker__navigation--previous{
  background-image: url('./images/left.svg');
  /* border-right-color: transparent; */
}

.appDatePicker .react-datepicker__day--selected{
  background-color: #d9ecf2;
  color: #002d40;
}

.appDatePicker .react-datepicker__day--outside-month {
  color: transparent;
  background-color: transparent;
  pointer-events: none;
  visibility: hidden;
}

/*
* Date Picker CSS Ends here
*/



/*
* Overlay CSS
*/
.overlay-info{
  position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 2;
    display: none;
    padding: 22px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-radius: 33px;
    background-color: #002d40;
}

.confirm-customer-call{
  position: fixed;
}

.create-table-dialog{
  position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 1;
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    
}



.cursor-pointer{
  cursor: pointer;
}

.blurred1{
  z-index: 1001;
  position: fixed;
  justify-content: center;
    align-items: center;
}

.itemoptions{
  z-index: 1001;
  position: fixed;
  overflow: auto;
}

.w-slider-mask{
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
}

.rec-carousel, .rec-carousel-wrapper, .rec-slider-container, .rec-slider, .rec-item-wrapper {
  height: 100% !important;
}
.rec-slider-container{
  margin: 0 3px 0 0px !important;
}

.itempreview .rec-pagination{
    display: flex;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 15px;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    background-color: #d9ecf2;
    font-size: 5px;
    line-height: 11px;
    position: absolute;
    bottom: 0px;
}

.itempreview .rec-dot{
  position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    background-color: rgba(34, 34, 34, 0.4);
    cursor: pointer;
    margin: 0 3px 0.5em;
    transition: background-color 100ms, color 100ms;
    box-shadow: none;
}

.itempreview .rec-dot_active{
  background-color: #222;
  box-shadow: none;
}

.itempreview .itemtxtlayer-2{
  background-color: #d9ecf2;
}

.itempreview .itemtxtlayer-2 .icons{
  height: 25px;
}

.itempreview .itemtxtlayer-2 .icons > img{
  max-height: 100%;
  max-width: 100%;
}


.active-item{
  background-color: rgb(23, 166, 183);
}

.picad1-loading{
  background-image: url('./images/time_dAsset-3.svg'), 
  -webkit-gradient(linear, left top, left bottom, from(#17a6b7), to(#17a6b7)) !important;
}
.picad1-error{
  background-image: url('./images/errorAsset-1.svg'),
  -webkit-gradient(linear, left top, left bottom, from(#ff414d), to(#ff414d)) !important;
}
.picad1-error:hover, .picad1-loading:hover{
  background-image: url('./images/tarshB2_dAsset-7.svg') !important;
}
.picad1{
  background-image: unset;
}
.picad1:hover{
  background-image: url("./images/tarshB2_dAsset-7.svg") !important;
}

.pos-rel{
  position: relative;
}

.text-block-140{
  background-color: #fff;
}
.deletedialog, .hidedialog{
  z-index: 10;
  position: fixed;
}

/* .overviewpreview, .itempreview, .menupreview{
  min-width: 100%;
} */

.w-slider.slider-3{
  width: 100%;
  height: 100%;
}

/*
* HTML 5 validation 
*/

input:invalid:focus {
  border:1px solid #ff414d;
}


.text-block-455{
  display: flex;
  align-items: center;
  justify-content: center;
}


.errorMessage{
  color: #f6f6f6;
    background: #e1316a;
    font-size: 12px;
    font-weight: 600;
    /* width: 70%; */
    text-align: center;
    border-radius: 10px;
    margin: 5px auto 10px;
    padding: 0 25px;
}

.textarea-5, textarea.w-input{
  min-width: 100%;
}

.placeholder-nopicture{
  background-image: url(./images/picture_dAsset-1.svg);
  background-position: 50% 25%;
  background-size: 66px 66px;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  background-color: #003f5a;

}



.syncing{
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(328.003deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  will-change: transform;
  -webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
}

.select_table_message{
  display: flex;
  opacity: 1
}

.wish{
  -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
}


@keyframes spin { 
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(359deg); } 
}



.navbarmobile{
  width: 100%;
}

.logouttab{
  position: absolute;
    left: 100%;
    width: max-content;
}

.data-content-section{
  min-height: 364px;
  position: relative;
}

.businessImage-section{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #002d40;
  background-image: none;
  min-height: 207px;
  position: relative;
}

.dropdown-toggle-21{
  padding: 3px;
}


.div-block-1037{
  /* width: 100%; */
}

#landingPageSlider .w-slide{
  background-size: contain;
}
.d-block{
  display: block;
}
.d-none {
  display: none;
}

.body-9{
  width: 100%;
    height: 100%;
    overflow: auto;
}

.nav-menu-right{
  display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.helpme_pop{
  z-index: 900;
}
.dropdown-22{
  position: relative;
  right: unset;
  margin-right: 0px;
    margin-left: auto;
}

.language-light .w-dropdown-toggle{
  border-color: #d9ecf2;
}
.language-light .w-dropdown-list{
  background-color: #d9ecf2;
}
.language-light .w-dropdown-list .text-block-394:hover{
  background-color: #002d40;
    color: #d9ecf2;
}


.language-dark .w-dropdown-toggle{
  background-color: #17a6b7;
    color: #002d40;
    border-color: transparent;
}
.language-dark .w-dropdown-toggle .text-block-402{
  color: #002d40;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;

}

.language-dark .w-dropdown-list{
  background-color: #c3dde5;
    box-shadow: 0 22px 33px -22px #002d40;
}
.language-dark .w-dropdown-list .text-block-394:hover{
  background-color: #17a6b7;
    color: #d9ecf2;
}


.language-medium .w-dropdown-toggle{
  background-color: #9dbfca;
  border-color: transparent;
}
.language-medium .w-dropdown-list{
  background-color: #c3dde5;
  box-shadow: 0 22px 33px -22px #002d40;
}
.language-medium .w-dropdown-list .text-block-394:hover{
  background-color: #17a6b7;
      color: #d9ecf2;
}

.pageloading{
  z-index: 900;
  display: flex;
}

.div-block-1052{
  min-width: 313px;
}

/* .top7{
  background-color: #17a6b7;
  background-image: url(./images/picture_dAsset-1.svg);
} */

button[disabled].savetxt {
  opacity: 0.5;
}

.status-indicator{
  width: 30px;
  height: 30px;
  
  position: absolute;
  top: 0;
  left: 0;
}

.status-indicator.active{
  background-color: #37a84a;
}
.status-indicator.inactive{
  background-color: #fe4450;
}

.paypalblock{
  word-wrap: break-word;
}

.text-lightblue{
  color: #d9ecf2;
}

.history{
  min-height: 518px;
  display: flex;
}

.tabbed-box{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.div-block-970{
  z-index: 1000;
}

.togglehistory{
  max-height: 275px;
  overflow: auto;
}

.datepciker-grid{
  grid-template-columns: 1fr 1fr;
  display: flex;
  position: relative;
  padding-right: 33px;
  padding-left: 33px;
  margin-top: 11px;
    margin-bottom: 11px;
    column-gap: 5px;
}
.datepciker-grid > .react-datepicker-wrapper {
  flex: 1;
}

@media screen and (max-width: 991px){
  .popupitem-2 {
    margin-top: 280px;
    margin-bottom: 35px;
    width: auto;
    height: auto;
    padding: 22px;
  }
}

@media screen and (max-width: 479px) {
  .popupitem-2 {
    margin-top: 410px;
  }
  .itemoptions {
    margin-bottom: auto;
    align-items: center;
  }

}
